//module
import React, { Component } from "react";
import propTypes from "prop-types";

class dashboardSidebar extends Component {
  /**
   * execute this function after component rendered
   */
  componentDidMount() {
    /**
     * get pathname from window
     */
    let pathName = window.location.href;
    /**
     * select dom by class
     * return value array
     */
    let dom = document.getElementsByClassName("side");
    /**
     * melakukan perulangan
     */
    for (let i = 0; i < dom.length; i++) {
      /**
       * melakukan perulangan
       */
      for (let index = 0; index < dom[i].children.length; index++) {
        /**
         * melakukan pengecekan apakah href sama dengan pathname
         */
        if (dom[i].children[index].firstElementChild.href === pathName) {
          /**
           * kalau sama maka buat attribut class dengan nilai active
           */
          dom[i].children[index].setAttribute("class", "active");
          /**
           * hentikan perulangan
           */
          break;
        } else {
          /**
           * melakukan perulangan
           */
          for (
            let index2 = 0;
            index2 < dom[i].children[index].children.length;
            index2++
          ) {
            for (var x in dom[i].children[index].children[index2].children) {
              if (
                dom[i].children[index].children[index2].children[x]
                  .firstChild &&
                dom[i].children[index].children[index2].children[x].firstChild
                  .href === pathName
              ) {
                /**
                 * kalau sama maka buat attribut class dengan nilai active
                 */
                dom[i].children[index].setAttribute("class", "active");
                /**
                 * hentikan perulangan
                 */
                break;
              }
            }
          }
        }
      }
    }
  }

  render() {
    //get user level
    const { level } = this.props;

    return (
      <div id="wrapper-sidebar" style={{ zIndex: "99" }}>
        <a href="#!" className="dashboard-responsive-nav-trigger">
          <i className="fa fa-reorder" /> Dashboard Navigation
        </a>

        <div className="dashboard-nav">
          <div className="dashboard-nav-inner">
            <ul data-submenu-title="Main" className="side">
              {level === 0 ? (
                <li>
                  <a href="/dashboard/overview">
                    <i className="im im-icon-Dashboard" /> Overview
                  </a>
                </li>
              ) : null}

              {level === 1 ? (
                <li>
                  <a href="/dashboard">
                    <i className="im im-icon-Dashboard" /> Dashboard
                  </a>
                </li>
              ) : null}

              {level === 1 ? (
                <li>
                  <a href="/dashboard/admin/nilai-ujian">
                    <i className="im im im-icon-Tablet" /> Nilai Ujian
                  </a>
                </li>
              ) : null}
            </ul>

            {level === 0 ? (
              <ul data-submenu-title="Grafik" className="side">
                <li>
                  <a href="/dashboard/perkembangan-nilai">
                    <i className="im im-icon-Bar-Chart" /> Perkembangan Nilai
                  </a>
                </li>
              </ul>
            ) : null}

            {level === 1 ? (
              <ul data-submenu-title="Ujian" className="side">
                <li>
                  <a href="/dashboard/paket">
                    <i className="im im-icon-Gift-Box" /> Paket
                  </a>
                </li>
                <li>
                  <a href="/dashboard/ujian">
                    <i className="im im-icon-Book" /> Ujian
                  </a>
                </li>
                <li>
                  <a href="/dashboard/soal">
                    <i className="im im-icon-Newspaper" /> Soal
                  </a>
                </li>
              </ul>
            ) : null}

            {level === 0 ? (
              <ul data-submenu-title="Ujian" className="side">
                <li>
                  <a href="/pilih-ujian">
                    <i className="im im-icon-Start" /> Mulai Ujian
                  </a>
                </li>
                <li>
                  <a href="/dashboard/nilai-ujian">
                    <i className="im im-icon-Tablet" /> Nilai Ujian
                  </a>
                </li>
              </ul>
            ) : null}

            {level === 1 ? (
              <ul data-submenu-title="Setting" className="side">
                <li>
                  <a href="/dashboard/users">
                    <i className="im im-icon-Add-User" /> User
                  </a>
                </li>

                <li>
                  <a href="/dashboard/cabang">
                    <i className="im im-icon-Home-3" /> Cabang
                  </a>
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

dashboardSidebar.propTypes = {
  level: propTypes.number.isRequired
};

export default dashboardSidebar;
