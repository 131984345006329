//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import axios from "axios";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

class paket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paket: [],
      pakets: [""],
      currentPage: 1,
      todosPerPage: 15,
      isEdit: false,
      id_paket: false,
      isLoaded: false,

      //add paket
      nama_paket: "",
      deskripsi_paket: ""
    };

    this.addPaketHandler = this.addPaketHandler.bind(this);
  }

  componentDidMount() {
    Edotensei.add(Scripts);
  }

  // async component
  async componentWillMount() {
    try {
      //get data
      let result = await axios.get(process.env.REACT_APP_API + "/paket");

      //update state
      this.setState({
        paket: result.data.data,
        pakets: result.data.data,
        isLoaded: true
      });
    } catch (error) {
      throw error;
    }
  }

  // handle search
  searchHandler(e) {
    //get paket
    var paket = this.state.pakets;

    try {
      //update state
      this.setState({ currentPage: 1 });

      //filter
      paket = paket.filter(item => {
        var joinItem = item.nama_paket + " " + item.deskripsi;

        return (
          joinItem.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });

      //update state
      this.setState({ paket });
    } catch (error) {
      alert(error);
    }
  }

  //handle tambah paket
  async addPaketHandler(e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Apakah data yang anda masukkan sudah benar?")) return;

    try {
      //request
      const result = await axios.post(process.env.REACT_APP_API + "/paket", {
        token: session.token,
        nama_paket: this.state.nama_paket,
        deskripsi: this.state.deskripsi_paket
      });

      //close modal
      document.querySelector(".mfp-close").click();

      //alert
      alert(result.data.message);

      //re-get paket
      this.componentWillMount();
    } catch (error) {
      throw error;
    }
  }

  async editPaketHandler(e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Simpan paket ini?")) return;

    try {
      //request
      const result = await axios({
        url: process.env.REACT_APP_API + "/paket",
        method: "put",
        data: {
          token: session.token,
          id_paket: this.state.id_paket,
          nama_paket: this.state.nama_paket,
          deskripsi: this.state.deskripsi_paket
        }
      });

      //close modal
      document.querySelector(".mfp-close").click();

      //alet
      alert(result.data.message);

      //re-get paket
      this.componentWillMount();
    } catch (error) {
      throw error;
    }
  }

  //handle delete
  async deleteHandler(id_paket, e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Hapus paket ini?")) return;

    await axios({
      url: process.env.REACT_APP_API + "/paket",
      method: "delete",
      data: {
        token: session.token,
        id_paket
      }
    });

    //re-get paket
    this.componentWillMount();
  }

  render() {
    //initial
    const { paket, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = paket.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(paket.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>

        <div hidden={!this.state.isLoaded}>
          {/** modals */}
          <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
            <div className="small-dialog-header">
              <h3>{this.state.isEdit ? "Edit Paket" : "Tambah Paket"}</h3>
            </div>
            <div className="message-reply margin-top-0">
              <form
                onSubmit={
                  this.state.isEdit
                    ? this.editPaketHandler.bind(this)
                    : this.addPaketHandler
                }
              >
                <div className="row with-forms">
                  <div className="col-md-12">
                    <h5>Nama Paket</h5>
                    <input
                      className="search-field"
                      type="text"
                      name="nama_paket"
                      value={this.state.nama_paket}
                      onChange={e => {
                        this.setState({ nama_paket: e.target.value });
                      }}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <h5>Deskripsi Paket</h5>
                    <textarea
                      cols="40"
                      rows="3"
                      name="deskripsi_paket"
                      value={this.state.deskripsi_paket}
                      onChange={e => {
                        //update state
                        this.setState({ deskripsi_paket: e.target.value });
                      }}
                      required
                    />
                  </div>
                </div>

                <button className="button">
                  {" "}
                  {this.state.isEdit ? "Simpan" : "Tambahkan"}
                </button>
              </form>
            </div>
          </div>

          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard-list-box margin-top-0">
                    <h4 className="clearfix" style={{ position: "relative" }}>
                      <a
                        style={{ float: "right", borderRadius: "5px" }}
                        href="#small-dialog"
                        className="button popup-with-zoom-anim"
                        id="modal"
                        onClick={() => {
                          this.setState({
                            isEdit: false,
                            id_paket: "",
                            nama_paket: "",
                            deskripsi_paket: ""
                          });
                        }}
                      >
                        <i className="sl sl-icon-plus" />
                        &nbsp; Tambah Paket
                      </a>

                      <i
                        style={{ top: "56%", position: "absolute" }}
                        className="fa  fa-search"
                      />
                      <input
                        style={{
                          marginBottom: "0",
                          borderColor: "transparent",
                          // borderBottom: "1px solid #dbdbdb",
                          boxShadow: "none"
                        }}
                        type="text"
                        className="input-text"
                        name="email"
                        placeholder="cari berdasarkan nama paket atau deskripsi paket"
                        autoComplete="off"
                        onChange={this.searchHandler.bind(this)}
                      />
                    </h4>

                    <table className="basic-table">
                      <thead style={{ background: "white" }}>
                        <tr>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Nama Paket
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Deskripsi Paket
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {currentTodos.length > 0 ? (
                          currentTodos.map((item, i) => (
                            <tr key={i}>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="20%"
                              >
                                {item.nama_paket}
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="45%"
                              >
                                {item.deskripsi}
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="35%"
                                align="right"
                              >
                                <a
                                  href="#!"
                                  className="button border"
                                  onClick={e => {
                                    e.preventDefault();

                                    //open modal
                                    document.querySelector("#modal").click();

                                    //update state
                                    this.setState({
                                      id_paket: item.id_paket,
                                      nama_paket: item.nama_paket,
                                      deskripsi_paket: item.deskripsi,
                                      isEdit: true
                                    });
                                  }}
                                >
                                  Edit Paket
                                </a>

                                <a
                                  href="#!"
                                  onClick={this.deleteHandler.bind(
                                    this,
                                    item.id_paket
                                  )}
                                  className="button border"
                                >
                                  Hapus Paket
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" align="center">
                              <h3>Paket Tidak Ditemukan</h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="pagination-container margin-top-30 margin-bottom-0">
                <nav className="pagination">
                  <ul>
                    {pageNumbers.map(number => (
                      <li key={number}>
                        <a
                          href="#!"
                          id={number}
                          className={
                            currentPage === number ? "current-page" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            //scroll up
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;

                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        >
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

paket.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default paket;
