//module
import React, { Component } from "react";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import Cryptr from "cryptr";
import Edotensei from "edotensei";

//get token
const session = JSON.parse(localStorage.getItem("token"));

//encrypt
const cryptr = new Cryptr(process.env.REACT_APP_SECRET_KEY);

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

class mulaiUjian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      soal: [],
      ujian: [],
      waktu_pengerjaan: "",
      petunjuk: "",
      isLoaded: false,
      soal_belum_diajawab: 0,

      //pagination
      currentPage: 1,
      todosPerPage: 1,
      countDown: "0 : 0 : 0",
      pengerjaan: "",
      lama_pengerjaan: "0 : 0 : 0"
    };
  }

  async submitJawaban() {
    //get soal
    const { soal, lama_pengerjaan } = this.state

    try {
      var result = await axios.post(
        process.env.REACT_APP_API + "/hasil-ujian",
        {
          token: session.token,
          soal,
          lama_pengerjaan
        }
      );

      //get the token
      const { token, displayName } = result.data;

      //save to local storage
      await localStorage.setItem(
        "token",
        JSON.stringify({ token, displayName })
      );

      //TODO redirect
      window.location.href = "/ujian/skor";
    } catch (error) {
      throw error;
    }
  }

  //timer
  countDown() {
    // Set the date we're counting down to
    var countDownDate = new Date(this.state.waktu_pengerjaan).getTime();

    var startDate = this.state.pengerjaan;

    try {
      var timer = setInterval(async () => {
        // Get todays date and time from server
        var [timeServer] = await Promise.all([
          axios.get(process.env.REACT_APP_API + "/time")
        ]);

        // Get todays date and time
        var now = timeServer.data.time
          ? timeServer.data.time
          : new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        startDate = new Date(startDate).getTime();

        var timeDifference = now - startDate;

        var secondsInADay = 60 * 60 * 1000 * 24,
          secondsInAHour = 60 * 60 * 1000;

        var detik = Math.floor(
          ((((timeDifference % secondsInADay) % secondsInAHour) % (60 * 1000)) /
            1000) *
          1
        );
        var menit = Math.floor(
          (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) *
          1
        );
        var jam = Math.floor(
          ((timeDifference % secondsInADay) / secondsInAHour) * 1
        );

        //jam
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        //menit
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        //detik
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        //update state
        this.setState({
          countDown: hours + " : " + minutes + " : " + seconds,
          lama_pengerjaan: jam + " : " + menit + " : " + detik
        });

        // If the count down is finished, write some text
        if (distance < 0) {
          //clear interval
          clearInterval(timer);

          //update state
          this.setState({ countDown: "0 : 0 : 0", isLoaded: false });

          //TODO
          //submit jawaban
          this.submitJawaban();
        }
      }, 1000);
    } catch (error) {
      console.log("lol");
    }
  }

  async componentWillMount() {
    try {
      var [waktu] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/mulai-ujian/" + session.token)
      ]);

      if (localStorage.getItem("pembahasan")) {
        var ujian = JSON.parse(
          cryptr.decrypt(localStorage.getItem("pembahasan"))
        );
      } else {
        var [ujian] = await Promise.all([
          axios.get(process.env.REACT_APP_API + "/mulai-ujian/" + session.token)
        ]);

        localStorage.setItem(
          "pembahasan",
          cryptr.encrypt(JSON.stringify(ujian))
        );
      }

      this.setState({
        isLoaded: true,
        soal: ujian.data.data,
        waktu_pengerjaan: waktu.data.waktu,
        petunjuk: waktu.data.petunjuk,
        pengerjaan: waktu.data.waktu_ujian,
        ujian
      });

      this.countDown(this);
    } catch (error) {
      alert("Ujian tidak tersedia");
      window.location.href = "/pilih-ujian";
    }
  }

  //dieksekusi ketika html selesai dirender
  componentDidMount() {
    //render script
    Edotensei.add(Scripts);
  }

  render() {
    //initial
    const { soal, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = soal.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(soal.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div style={{ background: "#f5f5f5" }}>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>
        <div hidden={!this.state.isLoaded}>
          {/** content */}
          <div className="container margin-top-30">
            <div className="row">
              <div className="col-md-12" style={{ paddingLeft: "0px" }}>
                <div className="col-md-8 style-2">
                  <div
                    style={{
                      background: "white",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      borderBottom: "none",
                      boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.06)",
                      borderRadius: "5px"
                    }}
                    className="toggle-wrap"
                  >
                    <span className="trigger ">
                      <a href="#" style={{ padding: "15px 0" }}>
                        Petunjuk Pengerjaan Soal
                        <i className="sl sl-icon-plus" />
                      </a>
                    </span>
                    <div className="toggle-container">
                      <p>{ReactHtmlParser(this.state.petunjuk)}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div style={{ padding: "20px 0" }} className="clearfix">
                  {currentPage !== 1 ? (
                    <a
                      style={{
                        float: "left",
                        borderRadius: "5px",
                        backgroundColor: "#fafafa",
                        borderColor: "#ddd",
                        color: "#333"
                      }}
                      href="#!"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ currentPage: currentPage - 1 });
                      }}
                      className="button border"
                    >
                      Soal sebelumnya
                    </a>
                  ) : null}

                  {currentPage !== soal.length ? (
                    <a
                      id="next"
                      href="#!"
                      onClick={e => {
                        e.preventDefault();

                        if (
                          soal[currentPage - 1].dipilih &&
                          soal[currentPage - 1].dipilih.status
                        ) {
                          this.setState({ currentPage: currentPage + 1 });
                        } else {
                          alert("Silahkan Menjawab Soal terlebih dahulu");
                        }
                      }}
                      style={{
                        float: "right",
                        borderRadius: "5px",
                        backgroundColor: "#118def",
                        borderColor: "#ddd",
                        color: "#fff"
                      }}
                      className="button border"
                    >
                      Simpan dan lanjutkan
                    </a>
                  ) : null}

                  {currentPage !== soal.length ? (
                    <a
                      id="next"
                      href="#!"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ currentPage: currentPage + 1 });
                      }}
                      style={{
                        float: "right",
                        borderRadius: "5px",
                        backgroundColor: "#fafafa",
                        borderColor: "#ddd",
                        color: "#333"
                      }}
                      className="button border"
                    >
                      Lewati soal
                    </a>
                  ) : null}
                </div>
                <div
                  style={{ background: "white" }}
                  className="dashboard-list-box margin-top-0"
                >
                  <h4 className="clearfix" style={{ position: "relative" }}>
                    Soal
                  </h4>
                  {currentTodos.map((item, i) => (
                    <div key={i}>
                      <div
                        style={{
                          padding: "10px 30px 0",
                          textAlign: "justify",
                          textJustify: "inter-word",
                          color: "black"
                        }}
                      >
                        {ReactHtmlParser(
                          item.no + ". " + item.pertanyaan.replace(/<p>/, "")
                        )}
                      </div>
                      <hr style={{ marginTop: "5px", marginBottom: "0" }} />

                      {item.soal_pilihan.map((soal_pilihan, x) => (
                        <div
                          style={{ paddingTop: "25px" }}
                          className="jawaban-container"
                          key={x}
                        >
                          <div
                            style={{
                              padding: "0 30px"
                            }}
                          >
                            <label>
                              <table width="100%">
                                <thead>
                                  <tr>
                                    <td width="5%"> {soal_pilihan.label}.</td>
                                    <td>
                                      {ReactHtmlParser(
                                        soal_pilihan.jawaban.replace(/<p>/, "")
                                      )}
                                    </td>
                                    <td width="5%" align="right">
                                      {" "}
                                      <i
                                        style={{
                                          float: "right",
                                          fontSize: "1.8em",
                                          cursor: "pointer",
                                          color: "#f91942"
                                        }}
                                        checked={
                                          soal_pilihan.checked
                                            ? soal_pilihan.checked
                                            : false
                                        }
                                        //attribute
                                        className={
                                          soal_pilihan.checked
                                            ? "fa fa-dot-circle-o  checkbox-" +
                                            item.id_soal
                                            : "fa fa-circle-o checkbox-" +
                                            item.id_soal
                                        }
                                        //method
                                        onClick={e => {
                                          //get value from attr checked
                                          var checked = e.target.checked;

                                          //get element
                                          var element = document.getElementsByClassName(
                                            "checkbox-" + item.id_soal
                                          );

                                          //remove checkbox
                                          for (const i in element) {
                                            if (element[i].className) {
                                              element[i].className =
                                                "fa fa-circle-o  checkbox-" +
                                                item.id_soal;
                                              element[i].checked = false;
                                            }
                                          }

                                          //remove child soal pilihan
                                          for (const i in item.soal_pilihan) {
                                            item.soal_pilihan[
                                              i
                                            ].checked = false;
                                          }

                                          //toggle
                                          if (checked) {
                                            e.target.className =
                                              "fa fa-circle-o  checkbox-" +
                                              item.id_soal;
                                            e.target.checked = false;

                                            //remove child soal
                                            item.dipilih = {
                                              status: false,
                                              label: "",
                                              poin: ""
                                            };

                                            //remove child soal pilihan
                                            soal_pilihan.checked = false;
                                          } else {
                                            //change classname
                                            e.target.className =
                                              "fa fa-dot-circle-o  checkbox-" +
                                              item.id_soal;
                                            e.target.checked = true;

                                            //add child soal
                                            item.dipilih = {
                                              status: true,
                                              label: soal_pilihan.label,
                                              poin: soal_pilihan.point
                                            };

                                            //remove child soal pilihan
                                            soal_pilihan.checked = true;

                                            //pindah soal otomatis
                                            // this.setState({
                                            //   currentPage:
                                            //     currentPage !== soal.length
                                            //       ? currentPage + 1
                                            //       : soal.length
                                            // });
                                          }

                                          //find index
                                          var index = soal.findIndex(
                                            arr => arr.id_soal === item.id_soal
                                          );

                                          //check index
                                          if (index !== -1) {
                                            soal[index] = item;

                                            var current_ujian = this.state
                                              .ujian;

                                            current_ujian.data.data = soal;

                                            localStorage.setItem(
                                              "pembahasan",
                                              cryptr.encrypt(
                                                JSON.stringify(current_ujian)
                                              )
                                            );

                                            this.setState({
                                              soal,
                                              ujian: current_ujian
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </thead>
                              </table>
                            </label>
                          </div>
                          <hr style={{ marginBottom: "0" }} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>

              {/** modal */}
              <div
                id="small-dialog"
                style={{ maxWidth: "1000px" }}
                className="zoom-anim-dialog mfp-hide clearfix"
              >
                <div
                  style={{ background: "transparent", marginBottom: "0" }}
                  className="small-dialog-header"
                >
                  <h3>Perhatian</h3>
                </div>
                <p>Apakah anda ingin mengakhiri ujian ini?</p>
                <p>
                  Jika "Ya" maka Anda sudah dinyatakan selesai mengikuti ujian,
                  dan anda tidak bisa memperbaiki lembar kerja Anda. Jika
                  "tidak" anda akan kembali ke lembar kerja dan silahkan untuk
                  melanjutkan menjawab atau memperbaiki jawaban Anda.{" "}
                </p>
                <p style={{ color: "rgb(249, 25, 66)" }}>
                  SOAL BELUM DIJAWAB : {this.state.soal_belum_diajawab}
                </p>
                <center>
                  <a
                    href="#!"
                    className="button border"
                    style={{
                      float: "left",
                      borderRadius: "5px",
                      backgroundColor: "#fafafa",
                      borderColor: "#ddd",
                      color: "#333",
                      marginTop: "5px"
                    }}
                    onClick={e => {
                      e.preventDefault();

                      //submit jawaban
                      this.submitJawaban();
                    }}
                  >
                    Ya (Saya sudah selesai menjawab soal)
                  </a>

                  <a
                    href="#!"
                    className="button border"
                    style={{
                      float: "left",
                      borderRadius: "5px",
                      backgroundColor: "#fafafa",
                      borderColor: "#ddd",
                      color: "#333",
                      marginTop: "5px"
                    }}
                    onClick={e => {
                      e.preventDefault();

                      //close modal
                      document.querySelector(".mfp-close").click();
                    }}
                  >
                    Tidak (Saya ingin melanjutkan menjawab soal)
                  </a>
                </center>
              </div>

              <div className="col-md-4">
                <div style={{ padding: "20px 0" }} className="clearfix">
                  <a
                    id="next"
                    href="#small-dialog"
                    onClick={e => {
                      e.preventDefault();

                      var x = 0;

                      let { soal } = this.state;

                      for (let i in soal) {
                        if (soal[i].dipilih) {
                          x += 1;
                        }
                      }

                      var hasil = soal.length - x;

                      this.setState({ soal_belum_diajawab: hasil });
                    }}
                    style={{
                      float: "right",
                      borderRadius: "5px",
                      backgroundColor: "#ba1b41",
                      borderColor: "#ddd",
                      color: "#fff"
                    }}
                    className="button border  popup-with-zoom-anim"
                  >
                    Selesai Ujian
                  </a>

                  <a
                    href="#!"
                    onClick={e => e.preventDefault()}
                    style={{
                      float: "left",
                      fontFamily: "Open Sans",
                      cursor: "default",
                      backgroundColor: "#fafafa",
                      borderColor: "#ddd",
                      color: "#333"
                    }}
                    className="button border"
                  >
                    <b>
                      <i className="im im-icon-Over-Time2" />{" "}
                      {this.state.countDown}
                    </b>
                  </a>
                </div>
                <div
                  style={{ background: "white", width: "100%" }}
                  className="dashboard-list-box margin-top-0"
                >
                  <h4 className="clearfix" style={{ position: "relative" }}>
                    Nomor
                  </h4>

                  <div
                    className="row"
                    style={{ textAlign: "left", padding: "15px 30px" }}
                  >
                    {pageNumbers.map((number, i) => (
                      <div
                        key={i}
                        className="col-md-3 col-sm-3"
                        style={{ paddingBottom: "5px" }}
                      >
                        <div
                          style={
                            soal[i].dipilih && soal[i].dipilih.status
                              ? {
                                background: "#00bfa5",
                                borderColor: "#00bfa5",
                                color: "white",
                                textAlign: "center",
                                cursor: "pointer"
                              }
                              : {
                                textAlign: "center",
                                cursor: "pointer"
                              }
                          }
                          id={number}
                          className={
                            currentPage === number ? "nomor dipilih" : "nomor"
                          }
                          onClick={e => {
                            e.preventDefault();
                            //scroll up
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;

                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        >
                          {number}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div> //end div wrapper
    );
  }
}

export default mulaiUjian;
