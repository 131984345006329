//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import Select from "react-select";
import firebase from "firebase/app";
import "jodit";
import JoditEditor from "jodit-react";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

// Add the css styles...
import "react-widgets/dist/css/react-widgets.css";
import "jodit/build/jodit.min.css";
import "firebase/storage"

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

//tempat menampuang jawaban
var arrJawaban = [{}];

class soal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //data awal
      ujian: [""],
      alfabet: ["A", "B", "C", "D"],
      jumlahJawaban: 5,
      listSoal: [],
      listSoals: [], //s = search
      isLoaded: false,
      isSubmit: false,

      //pagination
      currentPage: 1,
      todosPerPage: 15,

      //tambah soal
      pilih_ujian: [],
      soal: " ",
      jawaban: [],
      jawabanBenar: "",
      pembahasan: " ",
      file: []
    };
  }

  /**
   * @property Jodit jodit instance of native Jodit
   */
  jodit;
  setRef = jodit => (this.jodit = jodit);

  config = {
    uploader: {
      insertImageAsBase64URI: false,
      url: process.env.REACT_APP_API + "/file",
      imagesExtensions: ["jpg", "png", "jpeg", "gif"],
      method: "POST",
      isSuccess: resp => false,
      getMessage: resp => "Sedang mengupload gambar",
      buildData: data => {

        return new Promise((resolve, reject) => {

          const urltoFile = (url, filename, mimeType) => {

            return (fetch(url)

              .then(function (res) { return res.arrayBuffer(); })

              .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            )
          }

          const reader = new FileReader()

          reader.readAsDataURL(data.getAll('files[0]')[0])

          reader.onload = async () => {

            const file = await urltoFile(reader.result, "test.jpg", "image/jpeg")

            const storageRef = firebase.storage().ref()

            const campaignImageRef = storageRef.child("soal/" + file.lastModified + ".jpg")

            alert("sedang mengungggah gambar")

            const snap = campaignImageRef.put(file)

            snap.on("state_changed", snapshot => { }, err => { console.log(err) }, async () => {

              const url = await snap.snapshot.ref.getDownloadURL()

              const soal = this.state.soal + ` <img src=${url} alt=${url} />`

              this.setState({ soal })

            })

            return resolve({

              image: file

            })
          }
        })
      }
    }
  }

  config2 = {
    uploader: {
      insertImageAsBase64URI: false,
      url: process.env.REACT_APP_API + "/file",
      imagesExtensions: ["jpg", "png", "jpeg", "gif"],
      method: "POST",
      isSuccess: resp => false,
      getMessage: resp => "Sedang mengupload gambar",
      buildData: data => {

        return new Promise((resolve, reject) => {

          const urltoFile = (url, filename, mimeType) => {

            return (fetch(url)

              .then(function (res) { return res.arrayBuffer(); })

              .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            )
          }

          const reader = new FileReader()

          reader.readAsDataURL(data.getAll('files[0]')[0])

          reader.onload = async () => {

            const file = await urltoFile(reader.result, "test.jpg", "image/jpeg")

            const storageRef = firebase.storage().ref()

            const campaignImageRef = storageRef.child("soal/" + file.lastModified + ".jpg")

            alert("sedang mengungggah gambar")

            const snap = campaignImageRef.put(file)

            snap.on("state_changed", snapshot => { }, err => { console.log(err) }, async () => {

              const url = await snap.snapshot.ref.getDownloadURL()

              const pembahasan = this.state.pembahasan + ` <img src=${url} alt=${url} />`

              this.setState({ pembahasan })

            })

            return resolve({

              image: file

            })
          }
        })
      }
    }
  }

  componentDidMount() {
    //add scripts
    Edotensei.add(Scripts);

    //buat form input jawaban dinamis
    var alfabet = [];
    var i = "A".charCodeAt(0);
    var j = "Z".charCodeAt(0);

    for (; i <= j; ++i) {
      alfabet.push(String.fromCharCode(i));
    }

    //update state
    this.setState({ alfabet });
  }

  // async component
  async componentWillMount() {
    try {
      //get data
      let [ujian, soal] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/ujian"),
        axios.get(process.env.REACT_APP_API + "/soal")
      ]);

      //intial
      var list_ujian_ = [];

      //parse array to object label and value
      for (const i in ujian.data.data) {
        list_ujian_.push({
          value: ujian.data.data[i].id_ujian,
          label: ujian.data.data[i].nama_ujian
        });
      }

      //update state
      this.setState({
        ujian: list_ujian_,
        listSoal: soal.data.data,
        listSoals: soal.data.data,
        isLoaded: true
      });
    } catch (error) {
      throw error;
    }
  }

  // handle search
  searchHandler(e) {
    //get paket
    var soal = this.state.listSoals;

    try {
      //update state
      this.setState({ currentPage: 1 });

      //filter
      soal = soal.filter(item => {
        var joinItem = item.pertanyaan;

        for (const i in item.list_ujian) {
          joinItem += " " + item.list_ujian[i].nama_ujian;
        }

        return (
          joinItem.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });

      //update state
      this.setState({ listSoal: soal });

    } catch (error) {
      alert(error);
    }
  }

  async inputFileHandler(e) {
    if (e.target.files.length < 1) return;

    //get the file
    const file = e.target.files;

    //update state
    this.setState({ file: file[0] });

    //headers
    let headers = new Headers();

    //create any headers we want
    headers.append("Accept", "application/json");

    //bundle the files and data we want to send to the server
    let formData = new FormData();

    //create any form data
    formData.append("file", e.target.files[0]);

    //try
    try {
      const result = await axios.post(
        process.env.REACT_APP_API + "/file",
        formData,
        headers
      );

      var html = "";

      //check type
      if (result.data.type === "video") {
        html =
          "<center> <video width='420' height='340' controls> <source src=" +
          process.env.REACT_APP_API +
          "/" +
          result.data.path +
          " /> </video> </center>";
      } else {
        html =
          " <img src=" +
          process.env.REACT_APP_API +
          "/" +
          result.data.path +
          " />";
      }

      //update state
      this.setState({
        pembahasan: this.state.pembahasan + html
      });
    } catch (error) {
      alert(error);
    }
  }

  // Add handler
  async addSoalHandler(e) {

    e.preventDefault();

    //validate
    var soal_pilihan = this.state.jawaban;

    if (soal_pilihan.length <= 0) return alert("Jawaban wajib diisi!");

    const length = soal_pilihan.length - 1;

    for (let i in soal_pilihan) {


      if (!soal_pilihan[i].point) return alert('point tidak boleh kosong')

      if (soal_pilihan[i].point > 0) break

      if (i == length) return alert('salah satu point jawaban harus lebih dari 0')

    }

    var soal = {
      pertanyaan: this.state.soal,
      type: "pilihan ganda",
      jawaban: this.state.jawabanBenar,
      pembahasan: this.state.pembahasan,
      jenis_soal: "hello",
      soal_pilihan: JSON.stringify(soal_pilihan)
    };

    var ujian_soal = this.state.pilih_ujian;

    //validate
    if (ujian_soal.length <= 0) return alert("Ujian wajib diisi!");

    if (soal.pertanyaan.length <= 0) return alert("Soal wajib diisi!");

    if (soal.jawaban === "") return alert("Jawaban benar wajib diisi!");

    //confirm
    if (!window.confirm("Simpan soal ini?")) return;

    this.setState({ isSubmit: true })

    try {
      //request
      const result = await axios({
        url: process.env.REACT_APP_API + "/soal",
        method: "post",
        data: {
          token: session.token,
          soal,
          ujian_soal
        }
      });

      //close modal
      document.querySelector(".mfp-close").click();

      //alet
      alert(result.data.message);

      this.setState({ isSubmit: false })

      //re-get paket
      // this.componentWillMount();

      //clear data
      // this.setState({
      //   soal: " ",
      //   jawaban: [],
      //   jawabanBenar: "",
      //   pembahasan: "",
      //   file: []
      // });

      //reload page
      window.location.reload();

    } catch (error) {

      this.setState({ isSubmit: false })

      throw error;
    }
  }

  //Delete handler
  async deleteSoalHandler(id_soal, e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Hapus soal ini?")) return;

    //delete
    await axios({
      url: process.env.REACT_APP_API + "/soal",
      method: "delete",
      data: {
        token: session.token,
        id_soal
      }
    });

    //re-get paket
    this.componentWillMount();
  }

  render() {
    //intial
    const { jawaban, listSoal, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = listSoal.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(listSoal.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>

        <div hidden={!this.state.isLoaded}>
          {/** modals */}
          <div
            style={{ maxWidth: "1000px" }}
            id="small-dialog"
            className="zoom-anim-dialog mfp-hide"
          >
            <div className="small-dialog-header">
              <h3>Tambah Soal</h3>
            </div>
            <div className="message-reply margin-top-0">
              <form onSubmit={this.addSoalHandler.bind(this)}>
                <div className="row with-forms">
                  <div className="col-md-12">
                    <h5>Ujian</h5>
                    <Select
                      placeholder=""
                      isMulti={true}
                      options={this.state.ujian}
                      onChange={selected => {
                        this.setState({ pilih_ujian: selected });
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <br />
                    <h5>Soal</h5>
                    <JoditEditor
                      config={this.config}
                      editorRef={this.setRef}
                      value={this.state.soal}
                      onChange={value => (this.state.soal = value)}
                    />
                  </div>

                  <div style={{ padding: 0 }} className="col-md-12">
                    <br />
                    <h5 style={{ paddingLeft: "10px" }}>Jawaban</h5>
                    <div className="col-md-12">
                      <table width="100%">
                        <tbody>
                          {this.state.alfabet
                            .slice(0, this.state.jumlahJawaban)
                            .map((item, i) => (
                              <tr key={i}>
                                <td width="5%">
                                  <i
                                    style={{
                                      fontStyle: "normal",
                                      fontSize: "16px"
                                    }}
                                  >
                                    {item}.
                                  </i>
                                </td>
                                <td width="55%">
                                  {/** 
                              <div className="input-with-icon medium-icons">
                                <input
                                  type="text"
                                  autoComplete="false"
                                  name={"jawaban" + item}
                                  value={
                                    jawaban[i] ? jawaban[i].jawaban : ""
                                  }
                                  onChange={e => {
                                    arrJawaban[i] = {
                                      label: item,
                                      jawaban: e.target.value
                                    };
                                    this.setState({ jawaban: arrJawaban });
                                  }}
                                  required
                                />
                                <i
                                  style={{
                                    fontStyle: "normal",
                                    fontSize: "16px"
                                  }}
                                >
                                  {item}
                                </i>
                              </div>*/}
                                  <div>
                                    <JoditEditor
                                      config={{
                                        toolbarButtonSize: "small",
                                        uploader: {
                                          insertImageAsBase64URI: false,
                                          url: process.env.REACT_APP_API + "/file",
                                          imagesExtensions: ["jpg", "png", "jpeg", "gif"],
                                          method: "POST",
                                          format: "json",
                                          queryBuild: data => JSON.stringify(data),
                                          contentType: () => 'application/json',
                                          isSuccess: resp => false,
                                          getMessage: resp => "Sedang mengupload gambar",
                                          buildData: data => {

                                            return new Promise((resolve, reject) => {

                                              const urltoFile = (url, filename, mimeType) => {

                                                return (fetch(url)

                                                  .then(function (res) { return res.arrayBuffer(); })

                                                  .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
                                                )
                                              }

                                              const reader = new FileReader()

                                              reader.readAsDataURL(data.getAll('files[0]')[0])

                                              reader.onload = async () => {

                                                const file = await urltoFile(reader.result, "test.jpg", "image/jpeg")

                                                const storageRef = firebase.storage().ref()

                                                const campaignImageRef = storageRef.child("soal/" + file.lastModified + ".jpg")

                                                const snap = campaignImageRef.put(file)

                                                snap.on("state_changed", snapshot => { }, err => { console.log(err) }, async () => {

                                                  const url = await snap.snapshot.ref.getDownloadURL()

                                                  // const pembahasan = this.state.pembahasan + `<img src=${url} alt=${url} />`

                                                  arrJawaban[i] = { label: item, jawaban: jawaban[i] ? jawaban[i].jawaban + `<img src=${url} alt=${url} />` : "" + `<img src=${url} alt=${url} />`, point: jawaban[i] ? jawaban[i].point : false }

                                                  this.setState({ jawaban: arrJawaban })

                                                })

                                                return resolve({

                                                  image: file

                                                })
                                              }
                                            })
                                          }
                                        }
                                      }}
                                      value={
                                        jawaban[i] ? jawaban[i].jawaban : ""
                                      }

                                      editorRef={this.setRef}

                                      onChange={value => {

                                        arrJawaban[i] = {
                                          label: item,
                                          jawaban: value,
                                          point: jawaban[i] ? jawaban[i].point : false
                                        };

                                        this.state.jawaban = arrJawaban;
                                      }}
                                    />
                                    <br />
                                  </div>
                                </td>
                                <td width="20%" align="right">
                                  <input

                                    min="0"
                                    style={{ width: "80%" }}
                                    placeholder="point"
                                    type="number"
                                    value={
                                      jawaban[i] && jawaban[i].point
                                        ? jawaban[i].point
                                        : ""
                                    }
                                    onChange={e => {

                                      arrJawaban[i]
                                        ? (arrJawaban[i].point = e.target.value)
                                        : alert(
                                          "Input jawaban terlebih dahulu"
                                        );

                                      // this.state.jawaban = arrJawaban

                                      this.setState({ jawaban: arrJawaban });
                                    }}

                                    required
                                  />
                                </td>
                                <td align="right" valign="center">
                                  {i + 1 === this.state.jumlahJawaban ? (
                                    <p>
                                      {this.state.jumlahJawaban === 1 ? null : (
                                        <i
                                          style={{
                                            fontSize: "30px",
                                            cursor: "pointer",
                                            color: "#f91942",
                                            marginTop: "12px",
                                            marginRight: "10px"
                                          }}
                                          onClick={() => {
                                            arrJawaban.splice(
                                              this.state.jumlahJawaban - 1,
                                              1
                                            );

                                            this.setState({
                                              jumlahJawaban:
                                                this.state.jumlahJawaban - 1,
                                              jawaban: arrJawaban,
                                              jawabanBenar: ""
                                            });
                                          }}
                                          className="fa fa-minus-square-o"
                                        />
                                      )}

                                      <i
                                        style={{
                                          fontSize: "30px",
                                          cursor: "pointer",
                                          color: "#f91942",
                                          marginTop: "12px"
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            jumlahJawaban:
                                              this.state.jumlahJawaban + 1
                                          })
                                        }
                                        className="fa fa-plus-square-o"
                                      />
                                    </p>
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <h5>Jawaban yang benar</h5>
                    <select
                      value={this.state.jawabanBenar}
                      onChange={e =>
                        this.setState({ jawabanBenar: e.target.value })
                      }
                    >
                      <option value="" />
                      {this.state.alfabet
                        .slice(0, this.state.jumlahJawaban)
                        .map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>

                  {/*<div className="col-md-12">
                    <h5>File terkait (gambar atau video)</h5>
                    <label
                      style={{
                        height: "51px",
                        border: "1px solid #dbdbdb",
                        borderRadius: "3px",
                        padding: "10px 20px",
                        cursor: "pointer"
                      }}
                      htmlFor="input-file"
                    >
                      this.state.file.name
                        ? this.state.file.name
                        : "Pilih File Gambar atau Video"}
                      <input
                        hidden={true}
                        accept="image/*, video/*"
                        onChange={this.inputFileHandler.bind(this)}
                        id="input-file"
                        type="file"
                      />
                    </label>
                      </div>*/}

                  <div className="col-md-12">
                    <h5>Pembahasan Soal</h5>
                    <JoditEditor
                      config={this.config2}
                      editorRef={this.setRef}
                      value={this.state.pembahasan}
                      onChange={value => (this.state.pembahasan = value)}
                    />
                  </div>

                  <div className="col-md-12">
                    <br />
                    <button className="button">{this.state.isSubmit ? "Loading..." : "Tambahkan"}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard-list-box margin-top-0">
                    <h4 className="clearfix" style={{ position: "relative" }}>
                      <a
                        style={{ float: "right", borderRadius: "5px" }}
                        href="#small-dialog"
                        className="button popup-with-zoom-anim"
                        id="modal"
                      >
                        <i className="sl sl-icon-plus" />
                        &nbsp; Tambah Soal
                      </a>

                      <i
                        style={{ top: "56%", position: "absolute" }}
                        className="fa  fa-search"
                      />
                      <input
                        style={{
                          marginBottom: "0",
                          borderColor: "transparent",
                          // borderBottom: "1px solid #dbdbdb",
                          boxShadow: "none"
                        }}
                        type="text"
                        className="input-text"
                        name="email"
                        placeholder="cari berdasarkan ujian atau soal"
                        autoComplete="off"
                        onChange={this.searchHandler.bind(this)}
                      />
                    </h4>

                    <table className="basic-table">
                      <thead style={{ background: "white" }}>
                        <tr>
                          <th style={{ background: "white", color: "#333" }}>
                            Ujian
                          </th>
                          <th style={{ background: "white", color: "#333" }}>
                            Soal
                          </th>
                          <th style={{ background: "white", color: "#333" }} />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listSoal.length > 0 ? (
                          currentTodos.map((item, i) => (
                            <tr key={i}>
                              <td
                                width="25%"
                                style={{ borderBottom: "1px solid white" }}
                              >
                                {item.list_ujian.map((list, key) => (
                                  <a
                                    key={key}
                                    href="#!"
                                    style={{
                                      cursor: "default",
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                      borderColor: "#00bfa5",
                                      background: "#00bfa5",
                                      color: "white"
                                    }}
                                    className="border button"
                                  >
                                    {list.nama_ujian}
                                  </a>
                                ))}
                              </td>
                              <td style={{ borderBottom: "1px solid white" }}>
                                {ReactHtmlParser(item.pertanyaan)}
                              </td>
                              <td
                                style={{ borderBottom: "1px solid white" }}
                                width="35%"
                                align="right"
                              >
                                <a
                                  href={"/dashboard/soal/edit/" + item.id_soal}
                                  className="button border"
                                >
                                  Edit Soal
                                </a>

                                <a
                                  href="#!"
                                  onClick={this.deleteSoalHandler.bind(
                                    this,
                                    item.id_soal
                                  )}
                                  className="button border"
                                >
                                  Hapus Soal
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                            <tr>
                              <td
                                style={{ borderBottom: "1px solid white" }}
                                colSpan="3"
                                align="center"
                              >
                                <h3>Soal Tidak Ditemukan</h3>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="pagination-container margin-top-30 margin-bottom-0">
                <nav className="pagination">
                  <ul>
                    {pageNumbers.map(number => (
                      <li key={number}>
                        <a
                          href="#!"
                          id={number}
                          className={
                            currentPage === number ? "current-page" : ""
                          }
                          onClick={e => {
                            
                            e.preventDefault();
                            //scroll up
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;

                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        >
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

soal.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default soal;
