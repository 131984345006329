import React, { Component } from "react";
import axios from "axios";
import md5 from "md5";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //login
      username: "",
      password: "",

      //register
      reg_username: "",
      reg_password: "",
      reg_repassword: "",
      reg_paket: "",

      //function
      isSubmit: false,
      paket: [],
      popup: "daftar"
    };
  }

  componentWillMount() {
    //get data
    let request = axios.get(process.env.REACT_APP_API + "/paket");

    //promis
    request
      .then(result => {
        //return value
        return result.data;
      })
      .then(result => {
        //update state
        this.setState({ paket: result.data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidMount() {
    //navbar selector
    let navbar = document.querySelector("#header");
    let sticky = navbar.offsetTop;

    window.onscroll = () => {
      if (window.pageYOffset > sticky) {
        //change id
        navbar.id = "header-sticky";
      } else {
        navbar.id = "header";
      }
    }

  }

  /**
   * session handler
   * @param {object} data
   */
  handleSessions(data) {
    //check users
    if (!data.ok) {
      //alert call
      alert("Username atau password yang anda masukkan salah!");

      //udpdate state
      this.setState({ password: "", isSubmit: false });

      //return
      return;
    }

    //make session
    localStorage.setItem(
      "token",
      JSON.stringify({
        token: data.token,
        displayName: data.displayName,
        userLevel: data.userLevel
      })
    );

    if (data.userLevel === 1) {
      //redirect
      window.location.href = "/dashboard";
    } else {
      //redirect
      window.location.href = "/dashboard/overview";
    }
  }

  /**
   * handle login
   * @param {event} e
   */
  loginHandler(e) {
    e.preventDefault();

    //update state
    this.setState({ isSubmit: true });

    //check users
    const request = axios.post(process.env.REACT_APP_API + "/login", {
      username: this.state.username,
      password: md5(this.state.password)
    });

    //promise request
    request
      .then(response => {
        //return response
        return response.data;
      })
      .then(this.handleSessions.bind(this))
      .catch(err => {
        //call alert
        alert("Internal server error");

        //update state
        this.setState({ isSubmit: false });
      });
  } //end login

  registerHandler(e) {
    //prevent
    e.preventDefault();

    //initial
    const { reg_username, reg_password, reg_repassword } = this.state;

    //check password
    if (reg_password !== reg_repassword) {
      //call alert
      alert("Password yang anda masukkan tidak cocok!");

      //update state
      this.setState({ reg_password: "", reg_repassword: "" });
    } else if (reg_password.length < 8) {
      //call alert
      alert("Password minimal 8 karakter");

      //update state
      this.setState({ reg_password: "", reg_repassword: "" });

      //return
      return;
    }

    let conf = window.confirm("Apakah data yang anda masukkan sudah benar?");

    if (!conf) return;

    //Restfull
    var request = axios.post(process.env.REACT_APP_API + "/users", {
      username: reg_username,
      password: md5(reg_password),
      paket: this.state.reg_paket,
      userLevel: 0
    });

    //promise
    request
      .then(res => {
        //alert
        alert("Registrasi berhasil, silahkan login untuk memulai ujian!");

        //reload page
        if (res.data.ok) {
          window.location.reload();
        }

        //update state
        this.setState({
          reg_username: "",
          reg_password: "",
          reg_repassword: ""
        });
      })
      .catch(err => {
        alert("Username sudah ada!");

        //update state
        this.setState({
          reg_username: "",
          reg_password: "",
          reg_repassword: ""
        });
      });
  } //end register

  logoutHandler(e) {
    e.preventDefault();

    //dialog
    let conf = window.confirm("Logout sekarang?");

    //verify
    if (!conf) return;

    //remove token
    localStorage.removeItem("token");

    //reload page
    window.location.href = "/";
  }

  render() {
    //get auth
    const { auth } = this.props;

    return (
      <div>
        <header id="header-container" className="fixed header-transparent">
          <div id="header" className="not-sticky">
            <div className="container">
              <div id="sign-in-dialog" className="zoom-anim-dialog" style={{ boxShadow: "-1px 3px 4px 0px rgba(161,158,161,1)" }}>
                <div
                  style={{ background: "transparent", marginBottom: "0" }}
                  className="small-dialog-header"
                >
                  <h3>Exam Aidu</h3>
                </div>

                <div className="sign-in-form style-1">

                  <ul className="tabs-nav">
                    <li className=""><a href="#tab1">Masuk</a></li>
                    <li><a href="#tab2">Daftar</a></li>
                  </ul>

                  <div className="tabs-container alt">

                    {/* daftar */}
                    <div className="tab-content" id="tab1" style={{ display: "none" }}>
                      <form
                        onSubmit={this.loginHandler.bind(this)}
                        className="login"
                      >
                        <p className="form-row form-row-wide">
                          <label htmlFor="username">
                            Username:
                            <i className="im im-icon-Male" />
                            <input
                              type="text"
                              className="input-text"
                              name="username"
                              id="username"
                              onChange={e =>
                                this.setState({ username: e.target.value })
                              }
                              value={this.state.username}
                              disabled={this.state.isSubmit}
                              required
                            />
                          </label>
                        </p>

                        <p className="form-row form-row-wide">
                          <label htmlFor="password">
                            Password:
                            <i className="im im-icon-Lock-2" />
                            <input
                              className="input-text"
                              type="password"
                              name="password"
                              id="password"
                              onChange={e =>
                                this.setState({ password: e.target.value })
                              }
                              value={this.state.password}
                              disabled={this.state.isSubmit}
                              required
                            />
                          </label>
                        </p>

                        <div className="form-row">
                          <input
                            type="submit"
                            className="button border margin-top-5"
                            name="login"
                            disabled={this.state.isSubmit}
                            value={
                              this.state.isSubmit ? "Loading ..." : "Login"
                            }
                          />
                        </div>

                      </form>
                    </div>

                    {/* daftar */}
                    <div className="tab-content" id="tab2" style={{ display: "none" }}>
                      <form
                        className="login"
                      >
                        <p className="form-row form-row-wide">
                          <label htmlFor="username">
                            Nama:
                            <i className="im im-icon-Male" />
                            <input
                              type="text"
                              className="input-text"
                              name="nama"
                              required
                            />
                          </label>
                        </p>

                        <p className="form-row form-row-wide">
                          <label htmlFor="password">
                            Alamat:
                            <i className="im im-icon-Home-2" />
                            <input
                              className="input-text"
                              type="text"
                              name="alamat"
                              required
                            />
                          </label>
                        </p>

                        <p className="form-row form-row-wide">
                          <label htmlFor="password">
                            Email:
                            <i className="im im-icon-Email" />
                            <input
                              className="input-text"
                              type="email"
                              name="email"
                              required
                            />
                          </label>
                        </p>

                        <div className="form-row">
                          <input
                            type="submit"
                            className="button border margin-top-5"
                            name="login"
                            disabled={this.state.isSubmit}
                            value={
                              this.state.isSubmit ? "Loading ..." : "Daftar"
                            }
                          />
                        </div>

                      </form>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </header>
        <div className="clearfix" />
      </div>
    );
  }
}

export default Navbar;
