//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import axios from "axios";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get token
const session = JSON.parse(localStorage.getItem("token"));

class pilihujian extends Component {
  constructor(props) {
    super();

    this.state = {
      list_ujian: [],
      list_ujians: [],
      isLoaded: false,
      mempersiapkan: false,
      deskripsi: "",

      //pagination
      currentPage: 1,
      todosPerPage: 5
    };
  }

  async componentWillMount() {
    //get paket id
    // const { paket } = this.props;

    try {
      var [ujian] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/pilih-ujian/" + session.token)
      ]);

      //check ujian
      if (!ujian.data.ok) {
        //alert
        alert(ujian.data.data);

        //redirect
        return (window.location.href = ujian.data.location);
      }

      this.setState({
        list_ujian: ujian.data.data,
        list_ujians: ujian.data.data,
        deskripsi: ujian.data.deskripsi_paket,
        isLoaded: true
      });
    } catch (error) {
      throw error;
    }
  }

  // handle search
  searchHandler(e) {
    //get paket
    var list_ujian = this.state.list_ujians;

    try {
      //update state
      // this.setState({ currentPage: 1 });

      //filter
      list_ujian = list_ujian.filter(item => {
        //join
        var joinItem = item.nama_ujian;
        //search
        return (
          joinItem.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });

      //update state
      this.setState({ list_ujian });
    } catch (error) {
      alert(error);
    }
  }

  //dieksekusi ketika html selesai dirender
  componentDidMount() {
    //render script
    Edotensei.add(Scripts);

    //remove pembahasan
    try {
      localStorage.removeItem("pembahasan");
    } catch (error) {}
  }

  async handlePilihUjian(id_ujian, e) {
    e.preventDefault();

    //update state
    this.setState({ mempersiapkan: true });

    try {
      const result = await axios.post(
        process.env.REACT_APP_API + "/pilih-ujian",
        {
          token: session.token,
          id_ujian
        }
      );

      //get the token
      const { token, displayName } = result.data;

      //save to local storage
      await localStorage.setItem(
        "token",
        JSON.stringify({ token, displayName })
      );

      //redirect
      window.location.href = "/landing-ujian";
    } catch (error) {
      alert("Gagal mempersiapkan ujian, pastikan anda terhubung ke server!");

      //update state
      this.setState({ mempersiapkan: false });
    }
  }

  render() {
    //initial
    const { list_ujian, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = list_ujian.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(list_ujian.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>
        <div
          style={{
            backgroundImage: "url(/images/testimoni.png)",
            backgroundSize: "cover",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "100%"
          }}
          hidden={!this.state.isLoaded}
        >
          {/** content */}
          <div className="container margin-top-30">
            <div className="row">
              <div className="col-md-12">
                <a href="/dashboard/overview" className="button border">
                  Kembali
                </a>
              </div>

              <div className="col-md-12" style={{ marginTop: "100px" }}>
                <h2>
                  <b style={{ color: "white" }}>Tryout</b>
                </h2>

                <br />
                <hr
                  style={{
                    border: "none",
                    borderTop: "15px dotted white",
                    color: "#fff",
                    backgroundColor: "transparent",
                    height: "1px"
                  }}
                />
                <br />
                <p style={{ color: "white" }}>{this.state.deskripsi}</p>
                <br />

                {list_ujian.map((item, i) => (
                  <div key={i} className="col-md-4">
                    {item.jumlah_soal > 0 ? (
                      <a
                        href="#!"
                        onClick={this.handlePilihUjian.bind(
                          this,
                          item.id_ujian
                        )}
                        style={{ width: "100%", textAlign: "center" }}
                        className="button"
                        disabled={this.state.mempersiapkan ? true : false}
                      >
                        <b>{item.nama_ujian}</b>
                      </a>
                    ) : null}
                  </div>
                ))}

                <br />
                <br />
                <div
                  className="margin-top-50"
                  style={{
                    padding: "30px",
                    border: "5px dotted white"
                  }}
                >
                  {list_ujian.map((item, i) => (
                    <div key={i}>
                      <b style={{ color: "white" }}>{item.nama_ujian}</b>
                      <p style={{ color: "white" }}> {item.keterangan}</p>
                    </div>
                  ))}
                </div>

                {
                  //   <div className="dashboard-list-box margin-top-0">
                  //   <h4 className="clearfix" style={{ position: "relative" }}>
                  //     <a
                  //       style={{ float: "right", borderRadius: "5px" }}
                  //       href="/dashboard"
                  //       className="button "
                  //     >
                  //       kembali
                  //     </a>
                  //     <i
                  //       style={{ top: "56%", position: "absolute" }}
                  //       className="fa  fa-search"
                  //     />
                  //     <input
                  //       style={{
                  //         marginBottom: "0",
                  //         borderColor: "transparent",
                  //         // borderBottom: "1px solid #dbdbdb",
                  //         boxShadow: "none"
                  //       }}
                  //       type="text"
                  //       className="input-text"
                  //       name="email"
                  //       placeholder="cari berdasarkan nama ujian"
                  //       autoComplete="off"
                  //       onChange={this.searchHandler.bind(this)}
                  //     />
                  //   </h4>
                  //   <table className="basic-table">
                  //     <thead>
                  //       <tr>
                  //         <th style={{ background: "white", color: "#333" }}>
                  //           Nama Ujian
                  //         </th>
                  //         <th style={{ background: "white", color: "#333" }}>
                  //           Keterangan
                  //         </th>
                  //         <th style={{ background: "white", color: "#333" }} />
                  //       </tr>
                  //     </thead>
                  //     <tbody>
                  //       {currentTodos.map((item, i) => (
                  //         <tr key={i}>
                  //           <td width="20%">{item.nama_ujian}</td>
                  //           <td>
                  //             <p
                  //               style={{
                  //                 textAlign: "justify",
                  //                 textJustify: "inter-word"
                  //               }}
                  //             >
                  //               {" "}
                  //               {item.keterangan.slice(0, 250)} ...
                  //             </p>
                  //             <a
                  //               href="#!"
                  //               style={{
                  //                 cursor: "default",
                  //                 borderRadius: "5px",
                  //                 background: "transparent",
                  //                 color: "#f91942"
                  //               }}
                  //               className="button border"
                  //             >
                  //               <span style={{ color: "#707070" }}>
                  //                 Jumlah Soal
                  //               </span>{" "}
                  //               {item.jumlah_soal}
                  //             </a>
                  //             <a
                  //               href="#!"
                  //               style={{
                  //                 cursor: "default",
                  //                 borderRadius: "5px",
                  //                 background: "transparent",
                  //                 color: "#f91942"
                  //               }}
                  //               className="button border"
                  //             >
                  //               <span style={{ color: "#707070" }}>
                  //                 Waktu Pengerjaan
                  //               </span>{" "}
                  //               {item.waktu} Menit
                  //             </a>
                  //           </td>
                  //           <td width="30%" align="right">
                  //             {item.jumlah_soal > 0 ? (
                  //               // item.id_skor ? (
                  //               //   <a
                  //               //     href="#!"
                  //               //     style={{
                  //               //       background: "#00bfa5",
                  //               //       color: "white",
                  //               //       borderColor: "#00bfa5",
                  //               //       cursor: "default"
                  //               //     }}
                  //               //     className="button border"
                  //               //   >
                  //               //     Selesai
                  //               //   </a>
                  //               // ) : (
                  //               <a
                  //                 href="#!"
                  //                 onClick={this.handlePilihUjian.bind(
                  //                   this,
                  //                   item.id_ujian
                  //                 )}
                  //                 className="button border"
                  //                 disabled={this.state.mempersiapkan}
                  //               >
                  //                 {this.state.mempersiapkan
                  //                   ? "Sedang mempersiapkan ujian"
                  //                   : "Mulai Ujian"}
                  //               </a>
                  //             ) : null}
                  //           </td>
                  //         </tr>
                  //       ))}
                  //     </tbody>
                  //   </table>
                  // </div>
                }

                <div className="pagination-container margin-top-30 margin-bottom-0">
                  <nav className="pagination">
                    <ul>
                      {
                        //   pageNumbers.map(number => (
                        //   <li key={number}>
                        //     <a
                        //       href="#!"
                        //       id={number}
                        //       className={
                        //         currentPage === number ? "current-page" : ""
                        //       }
                        //       onClick={e => {
                        //         e.preventDefault();
                        //         //scroll up
                        //         document.body.scrollTop = 0;
                        //         document.documentElement.scrollTop = 0;
                        //         //update state
                        //         this.setState({
                        //           currentPage: Number(e.target.id)
                        //         });
                        //       }}
                        //     >
                        //       {number}
                        //     </a>
                        //   </li>
                        // ))
                      }
                    </ul>
                  </nav>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div> //end div wrapper
    );
  }
}

export default pilihujian;
