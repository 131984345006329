//module
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import firebase from "firebase/app";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//page
// import LandingPage from "./Landing-page";
import AboutPage from "./about-page";
// import PilihPaket from "./pilih-paket-page";
import PilihUjian from "./pilih-ujian-page";
import LandingUjian from "./landing-ujian-page";
import MulaiUjian from "./mulai-ujian-page";
import Scoring from "./scoring-page";

//dashboard admin
import Redirect from "./Dashboard/Redirect"
import Overview from "./Dashboard/Overview";
import AdminDashboard from "./Dashboard/Admin-dashboard";
import AdminNilaiUjian from "./Dashboard/Admin-nilai-ujian";
import Paket from "./Dashboard/Paket";
import Ujian from "./Dashboard/Ujian";
import Soal from "./Dashboard/Soal";
import EditSoal from "./Dashboard/Include/Soal-component/edit-soal";
import User from "./Dashboard/Users";
import Cabang from "./Dashboard/Cabang";
import AdminTentang from "./Dashboard/Admin-tentang";

//dashboard user
import NilaiUjian from "./Dashboard/Nilai-ujian";
import PerkembanganNilai from "./Dashboard/perkembangan-nilai";

//service workers
import * as serviceWorker from "./serviceWorker";

const firebaseConfig = {
  apiKey: "AIzaSyBKx9Ba5iaTGOuxNn3Si58KuJ0QzvxpzRI",
  authDomain: "aidu-59443.firebaseapp.com",
  databaseURL: "https://aidu-59443.firebaseio.com",
  projectId: "aidu-59443",
  storageBucket: "gs://aidu-59443.appspot.com/",
  messagingSenderId: "455784466978",
  appId: "1:455784466978:web:358d39d2c9e90a857d987b"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

/**
 * resolve auth
 * @param {boolean} value
 */
function resolve(value) {
  //router
  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        {/** landing page */}

        {/* <Route
          exact
          path="exam.aidu-education.com"
          render={props => (
            <LandingPage
              {...props}
              auth={value.status}
              level={value.userLevel}
            />
          )}
        /> */}

        <Route
          exact
          path="/redirect/:token/:displayName/:userLevel"
          render={props => (<Redirect  {...props} />)}
        />

        {/** about page */}
        <Route
          exact
          path="/tentang"
          render={props => (
            <AboutPage {...props} auth={value.status} level={value.userLevel} />
          )}
        />

        {/** pilih paket 
        <Route
          exact
          path="/pilih-paket"
          render={props =>
            value.status && value.userLevel === 0 ? (
              <PilihPaket {...props} auth={value.status} />
            ) : (
              (window.location.href = "exam.aidu-education.com")
            )
          }
        />*/}

        {/** pilih ujian */}
        <Route
          exact
          path="/pilih-ujian"
          render={props =>
            value.status && value.userLevel === 0 ? (
              <PilihUjian {...props} auth={value.status} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** landing ujian */}
        <Route
          exact
          path="/landing-ujian"
          render={props =>
            value.status && value.userLevel === 0 ? (
              <LandingUjian {...props} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** mulai ujian */}
        <Route
          exact
          path="/mulai-ujian/ujian"
          render={props =>
            value.status && value.userLevel === 0 ? (
              <MulaiUjian {...props} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** scoring page */}
        <Route
          exact
          path="/ujian/skor"
          render={props =>
            value.status && value.userLevel === 0 ? (
              <Scoring {...props} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard overview */}
        <Route
          exact
          path="/dashboard"
          render={props =>
            value.status && value.userLevel === 1
              ? <AdminDashboard {...props} auth={value} userLevel={value.userLevel} />
              : window.location.href = "exam.aidu-education.com"
          }
        />

        {/** dashboard overview */}
        <Route
          exact
          path="/dashboard/overview"
          render={props =>
            value.status && value.userLevel === 0 ? (
              <Overview {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard admin nilai ujian */}
        <Route
          exact
          path="/dashboard/admin/nilai-ujian"
          render={props =>
            value.status && value.userLevel === 1 ? (
              <AdminNilaiUjian {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard paket */}
        <Route
          exact
          path="/dashboard/paket"
          render={props =>
            value.status && value.userLevel === 1 ? (
              <Paket {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard ujian */}
        <Route
          exact
          path="/dashboard/ujian"
          render={props =>
            value.status && value.userLevel === 1 ? (
              <Ujian {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard soal */}
        <Route
          exact
          path="/dashboard/soal"
          render={props =>
            value.status && value.userLevel === 1 ? (
              <Soal {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard edit soal */}
        <Route
          exact
          path="/dashboard/soal/edit/:id_soal"
          render={props =>
            value.status && value.userLevel === 1 ? (
              <EditSoal {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard user*/}
        <Route
          exact
          path="/dashboard/users"
          render={props =>
            value.status && value.userLevel === 1 ? (
              <User {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard nilai ujian*/}
        <Route
          exact
          path="/dashboard/nilai-ujian"
          render={props =>
            value.status && value.userLevel === 0 ? (
              <NilaiUjian {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard nilai ujian*/}
        <Route
          exact
          path="/dashboard/perkembangan-nilai"
          render={props =>
            value.status && value.userLevel === 0 ? (
              <PerkembanganNilai {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard admin tentang*/}
        <Route
          exact
          path="/dashboard/cabang"
          render={props =>
            value.status && value.userLevel === 1 ? (
              <Cabang {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />

        {/** dashboard admin tentang*/}
        <Route
          exact
          path="/dashboard/edit-tentang"
          render={props =>
            value.status && value.userLevel === 1 ? (
              <AdminTentang {...props} userLevel={value.userLevel} />
            ) : (
                (window.location.href = "exam.aidu-education.com")
              )
          }
        />
      </Switch>
    </BrowserRouter>,
    document.getElementById("wrapper")
  );
}

//check token in the server
(async () => {
  //get token from local storage
  var session = JSON.parse(localStorage.getItem("token"));

  //check token
  if (!session) session = "undefined";

  try {
    //fetch data
    const [value] = await Promise.all([
      axios.post(process.env.REACT_APP_API + "/tokenVerify", {
        token: session.token
      })
    ]);

    //return result
    return resolve(value.data);
  } catch (err) {
    //handle error
    localStorage.removeItem("token");
    resolve({ status: false });
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
