import React, { Component } from "react";
import axios from "axios";

class Redirect extends Component {

    async componentWillMount() {

        try {

            const { token, displayName, userLevel } = this.props.match.params;

            await axios.post(process.env.REACT_APP_API + "/tokenVerify", {token})

            localStorage.setItem("token",
                JSON.stringify({
                    token,
                    displayName,
                    userLevel
                })
            );

            window.location.href = Number(userLevel) === 1 ? "/dashboard" : "/dashboard/overview"

        } catch (error) {

            // todo
            window.location.href = "https://aidu-education.com"

        }

    }

    render() {

        return (
            <div>
                <center>
                    <div style={{ marginTop: "20%" }} className="lds-facebook">
                        <div />
                        <div />
                        <div />
                    </div>
                </center>
            </div>
        )
    }

}

export default Redirect