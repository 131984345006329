//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import axios from "axios";
// import md5 from "md5";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

class users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paket: [],
      cabang: [],
      list_users: [],
      list_users_: [],
      isEdit: false,
      isLoaded: false,
      id_user: 0,

      //pagination
      currentPage: 1,
      todosPerPage: 10,

      //tambah user
      reg_username: "",
      reg_paket: "",
      reg_cabang: "",
      reg_nama_user: "",
      reg_nomor_hp: "",
      reg_email: "",
      reg_aktif: 0,
    };
  }

  componentDidMount() {
    Edotensei.add(Scripts);
  }

  // async component
  async componentWillMount() {

    try {
      //get data
      let [result, users, cabang] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/paket"),
        axios.get(process.env.REACT_APP_API + "/users"),
        axios.get(process.env.REACT_APP_API + "/cabang")
      ]);

      for (const i in users.data.data) {
        let filter = result.data.data.filter(item => {
          return item.id_paket === users.data.data[i].paket;
        });

        users.data.data[i].nama_paket =
          filter.length > 0 ? filter[0].nama_paket : "";
      }

      //update state
      this.setState({
        paket: result.data.data,
        cabang: cabang.data.data,
        list_users: users.data.data.reverse(),
        list_users_: users.data.data,
        isLoaded: true,
        reg_cabang: cabang.data.data[0].nama_cabang
      });
    } catch (error) {
      throw error;
    }
  }

  // handle search
  searchHandler(e) {
    //get paket
    var list_users = this.state.list_users_;

    try {
      //update state
      //   this.setState({ currentPage: 1 });

      //filter
      list_users = list_users.filter(item => {
        var joinItem =
          item.nama_paket + " " + item.username + " " + item.nama_user;

        return (
          joinItem.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });

      //update state
      this.setState({ list_users });
    } catch (error) {
      alert(error);
    }
  }

  async updateUsersHandler(e) {

    e.preventDefault();

    //initial
    var {
      reg_username,
      reg_paket,
      reg_cabang,
      reg_nama_user,
      reg_nomor_hp,
      reg_email,
      reg_aktif
    } = this.state;

    //check paket
    if (!reg_paket) return alert("Pilih paket ujian!");

    let conf = window.confirm("Simpan user ini?");

    if (!conf) return;

    try {
      //request
      const result = await axios({
        url: process.env.REACT_APP_API + "/users",
        method: "put",
        data: {
          token: session.token,
          id_user: this.state.id_user,
          nama_user: reg_nama_user,
          nomor_hp: reg_nomor_hp,
          email: reg_email,
          cabang: reg_cabang,
          paket: reg_paket,
          username: reg_username,
          aktif: reg_aktif
        }
      });

      //alert
      alert(result.data.message);

      //close modal
      document.querySelector(".mfp-close").click();

      //re-get paket
      this.componentWillMount();

    } catch (error) {

      throw error;
    }
  }

  //handle delete
  async deleteHandler(id_user, e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Hapus user ini?")) return;

    await axios({
      url: process.env.REACT_APP_API + "/users",
      method: "delete",
      data: {
        token: session.token,
        id_user
      }
    });

    //re-get paket
    this.componentWillMount();
  }

  async verfikasiHandler(data, e) {

    e.preventDefault()

    if (data.paket === 0) return alert("Paket belum dipilih")

    if (!window.confirm("Verifikasi user ini?")) return;

    try {
      //request
      const result = await axios({
        url: process.env.REACT_APP_API + "/users",
        method: "put",
        data: { ...data, aktif: 1, token: session.token }
      });

      //alert
      alert(result.data.message);

      //re-get paket
      this.componentWillMount();

    } catch (error) {

      throw error;
    }

  }

  render() {
    //initial
    const { list_users, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = list_users.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(list_users.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>

        <div hidden={!this.state.isLoaded}>
          {/** modals */}
          <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
            <div className="small-dialog-header">
              <h3>{this.state.isEdit ? "Edit User" : "Tambah Users"}</h3>
            </div>
            <div className="message-reply margin-top-0">
              <form
                onSubmit={this.updateUsersHandler.bind(this)}
              >

                <div className="col-md-12">
                  <h5>Nama </h5>
                  <input
                    type="text"
                    className="input-text"
                    value={this.state.reg_nama_user}
                    onChange={e =>
                      this.setState({
                        reg_nama_user: e.target.value
                      })
                    }
                    required
                  />
                </div>

                <div className="col-md-12">
                  <h5>Nomor Hp </h5>
                  <input
                    type="text"
                    className="input-text"
                    value={this.state.reg_nomor_hp}
                    onChange={e =>
                      this.setState({
                        reg_nomor_hp: e.target.value
                      })
                    }
                    required
                  />
                </div>

                <div className="col-md-12">
                  <h5>Email</h5>
                  <input
                    type="text"
                    className="input-text"
                    value={this.state.reg_email}
                    onChange={e =>
                      this.setState({
                        reg_email: e.target.value
                      })
                    }
                    required
                  />
                </div>

                <div className="col-md-12">
                  <h5>Sekolah</h5>
                  <select
                    value={this.state.reg_cabang}
                    onChange={e =>
                      this.setState({
                        reg_cabang: e.target.value
                      })
                    }
                    required
                  >
                    <option value="">Pilih Sekolah</option>
                    {this.state.cabang.map((item, i) => (
                      <option key={i} value={item.nama_cabang}>
                        {item.nama_cabang}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="col-md-12"
                >
                  <h5>Paket</h5>
                  <select
                    value={this.state.reg_paket}
                    onChange={e =>
                      this.setState({
                        reg_paket: e.target.value
                      })
                    }
                    required
                  >
                    <option value="">Pilih Paket</option>
                    {this.state.paket.map((item, i) => (
                      <option key={i} value={item.id_paket}>
                        {item.nama_paket}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12">
                  <h5>Username</h5>
                  <input
                    type="text"
                    className="input-text"
                    value={this.state.reg_username}
                    onChange={e =>
                      this.setState({
                        reg_username: e.target.value
                      })
                    }
                    required
                  />
                </div>

                {/* </div> */}

                <button className="button">
                  {" "}
                  {this.state.isEdit ? "Simpan" : "Tambahkan"}
                </button>
              </form>
            </div>
          </div>

          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard-list-box margin-top-0">
                    <h4 className="clearfix" style={{ position: "relative" }}>

                      <div hidden={true}>
                        <a
                          style={{ float: "right", borderRadius: "5px" }}
                          href="#small-dialog"
                          className="button popup-with-zoom-anim"
                          id="modal"
                        >
                          <i className="sl sl-icon-plus" /> &nbsp;Tambah User
                      </a>
                      </div>

                      <i
                        style={{ top: "42%", position: "absolute" }}
                        className="fa  fa-search"
                      />

                      <input
                        style={{
                          marginBottom: "0",
                          borderColor: "transparent",
                          boxShadow: "none"
                        }}
                        type="text"
                        className="input-text"
                        name="email"
                        placeholder="cari berdasarkan paket atau nama user"
                        autoComplete="off"
                        onChange={this.searchHandler.bind(this)}
                      />
                    </h4>

                    <table className="basic-table">
                      <thead style={{ background: "white" }}>
                        <tr>
                          <th style={{ background: "white", color: "#333" }}>
                            Paket
                          </th>
                          <th style={{ background: "white", color: "#333" }}>
                            User
                          </th>
                          <th style={{ background: "white", color: "#333" }}>
                            Sekolah
                          </th>
                          <th style={{ background: "white", color: "#333" }} />
                        </tr>
                      </thead>
                      <tbody>
                        {currentTodos.map((item, i) =>
                          item.status !== 1 ? (
                            <tr key={i}>
                              <td style={{ borderBottom: "1px solid white" }}>
                                {item.nama_paket ? (
                                  item.nama_paket
                                ) : item.status === 1 ? (
                                  "Admin"
                                ) : (
                                      <span style={{ color: "#f91942" }}>
                                        Paket Belum Dipilih
                                  </span>
                                    )}
                              </td>
                              <td style={{ borderBottom: "1px solid white" }}>
                                <i
                                  style={{
                                    marginRight: "5px",
                                    padding: "5px",
                                    background: item.aktif === 1 ? "rgb(0, 191, 165)" : "#f91942",
                                    color: "white",
                                    borderRadius: "50%"
                                  }}
                                  className="im im-icon-User"
                                />{" "}
                                {item.nama_user}
                              </td>
                              <td style={{ borderBottom: "1px solid white" }}>
                                {item.cabang}
                              </td>
                              <td
                                style={{ borderBottom: "1px solid white" }}
                                width="40%"
                                align="right"
                              >
                                <a
                                  href="#small-dialog"
                                  className="button popup-with-zoom-anim"
                                  onClick={e => {

                                    e.preventDefault()

                                    //open modal
                                    document.querySelector("#modal").click();

                                    //update state
                                    this.setState({
                                      isEdit: true,
                                      id_user: item.id_user,
                                      reg_username: item.username,
                                      reg_paket: item.paket,
                                      reg_cabang: item.cabang,
                                      reg_nama_user: item.nama_user,
                                      reg_nomor_hp: item.nomor_hp,
                                      reg_email: item.email,
                                      reg_aktif: 1,

                                    })

                                  }}
                                >
                                  Edit User
                                </a>

                                <a
                                  href="#!"
                                  onClick={this.deleteHandler.bind(
                                    this,
                                    item.id_user
                                  )}
                                  className="button"
                                  style={{ marginLeft: "5px" }}
                                >
                                  Hapus user
                                </a>

                                {
                                  item.aktif !== 1
                                    ? (
                                      <a
                                        href="#!"
                                        className="button"
                                        style={{ marginLeft: "5px", background: "rgb(0, 191, 165)" }}
                                        onClick={this.verfikasiHandler.bind(this, item)}
                                      >
                                        Verfikasi
                                      </a>
                                    )
                                    : null
                                }

                              </td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="pagination-container margin-top-30 margin-bottom-0">
                <nav className="pagination">
                  <ul>
                    {pageNumbers.map(number => (
                      <li key={number}>
                        <a
                          href="#!"
                          id={number}
                          className={
                            currentPage === number ? "current-page" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            //scroll up
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;

                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        >
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

users.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default users;
