//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";

//component
import Navbar from "./Include/Navbar";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

class aboutPage extends Component {
  constructor(props) {
    super();

    this.state = {
      kontent: ""
    };
  }

  //dieksekusi ketika html selesai dirender
  componentDidMount() {
    //render script
    Edotensei.add(Scripts);
  }

  async componentWillMount() {
    try {
      //get data
      const result = await axios.get(process.env.REACT_APP_API + "/tentang/");

      //update state
      this.setState({
        kontent: result.data.data.kontent
      });
    } catch (error) {
      throw error;
    }
  }

  render() {
    const { auth } = this.props;

    return (
      <div>
        {/** navbar */}
        <Navbar auth={auth} />

        {/** content */}
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Tentang ETC.</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {ReactHtmlParser(this.state.kontent)}
            </div>
          </div>
        </div>

        {/** footer */}
        <div className="col-md-12 margin-top-20">
          <div style={{ marginTop: "0" }} className="copyrights">
            © 2018 ETC. All Rights Reserved.
          </div>
        </div>
      </div> //end div wrapper
    );
  }
}

export default aboutPage;
