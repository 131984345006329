import React, { Component } from "react";

class Navbar extends Component {
  logoutHandler(e) {
    e.preventDefault();

    //dialog
    let conf = window.confirm("Logout sekarang?");

    //verify
    if (!conf) return;

    //remove token
    localStorage.removeItem("token");

    //reload page
    window.location.href = "https://aidu-education.com";
  }

  render() {
    //get user level
    const { level } = this.props;

    return (
      <header id="header-container" className="fixed fullwidth dashboard">
        <div
          style={{
            background: "white",
            padding: "18px 0 8px",
            boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.12)"
          }}
          id="header"
          className="not-sticky"
        >
          <div className="container">
            <div style={{ width: "30%" }} className="left-side">
              <div id="logo">
                <a href="/">
                  <img src="/images/logo/logo2.png" alt="" />
                </a>
                <a href="/" className="dashboard-logo">
                  <img src="/images/logo/logo2.png" alt="" />
                </a>
              </div>

              <div className="mmenu-trigger">
                <button className="hamburger hamburger--collapse" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>

              <nav id="navigation" className="style-1">
                <ul id="responsive">
                  {level === 0 ? (
                    <li className="dashboard-navigation">
                      <a href="/dashboard/overview">
                        <i className="im im-icon-Dashboard" /> Overview
                      </a>
                    </li>
                  ) : null}

                  {level === 1 ? (
                    <li className="dashboard-navigation">
                      <a href="/dashboard">
                        <i className="im im-icon-Dashboard" /> Dashboard
                      </a>
                    </li>
                  ) : null}

                  {level === 1 ? (
                    <li className="dashboard-navigation">
                      <a href="/dashboard/admin/nilai-ujian">
                        <i className="im im im-icon-Tablet" /> Nilai Ujian
                      </a>
                    </li>
                  ) : null}

                  {level === 0 ? (
                    <li className="dashboard-navigation">
                      <a href="/dashboard/perkembangan-nilai">
                        <i className="im im-icon-Bar-Chart" /> Perkembangan
                        Nilai
                      </a>
                    </li>
                  ) : null}

                  {level === 1 ? (
                    <ul data-submenu-title="Ujian" className="side">
                      <li className="dashboard-navigation">
                        <a href="/dashboard/paket">
                          <i className="im im-icon-Gift-Box" /> Paket
                        </a>
                      </li>
                      <li className="dashboard-navigation">
                        <a href="/dashboard/ujian">
                          <i className="im im-icon-Book" /> Ujian
                        </a>
                      </li>
                      <li className="dashboard-navigation">
                        <a href="/dashboard/soal">
                          <i className="im im-icon-Newspaper" /> Soal
                        </a>
                      </li>
                    </ul>
                  ) : null}

                  {level === 0 ? (
                    <ul data-submenu-title="Ujian" className="side">
                      <li className="dashboard-navigation">
                        <a href="/pilih-paket">
                          <i className="im im-icon-Start" /> Mulai Ujian
                        </a>
                      </li>
                      <li className="dashboard-navigation">
                        <a href="/dashboard/nilai-ujian">
                          <i className="im im-icon-Tablet" /> Nilai Ujian
                        </a>
                      </li>
                    </ul>
                  ) : null}

                  {level === 1 ? (
                    <ul data-submenu-title="Setting" className="side">
                      <li className="dashboard-navigation">
                        <a href="/dashboard/users">
                          <i className="im im-icon-Add-User" /> User
                        </a>
                      </li>
                      <li className="dashboard-navigation">
                        <a href="/dashboard/cabang">
                          <i className="im im-icon-Home-3" /> Cabang
                        </a>
                      </li>
                    </ul>
                  ) : null}
                </ul>
              </nav>
              <div className="clearfix" />
            </div>

            <div style={{ display: "block" }} className="right-side">
              <div className="header-widget">
                <div className="user-menu">
                  <div className="user-name">
                    Hi, {JSON.parse(localStorage.getItem("token")).displayName}
                  </div>
                  <ul>
                    <li>
                      <a href="/">
                        <i className="sl sl-icon-home" /> Home
                      </a>
                    </li>
                    {JSON.parse(localStorage.getItem("token")).userLevel ===
                    1 ? (
                      <li>
                        <a href="/dashboard">
                          <i className="sl sl-icon-settings" /> Dashboard
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a href="/dashboard/overview">
                          <i className="sl sl-icon-settings" /> overview
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        href="#!"
                        onClick={this.logoutHandler.bind(this)}
                        className="sign-in"
                      >
                        <i className="sl sl-icon-power" /> Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Navbar;
